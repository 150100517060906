$primary-color: #e2293f;
$scale: 3;
$squareSize: 50px;
$cornerSize: ceil($squareSize / $scale);
$cornerPosition: ceil($cornerSize / $scale);

@keyframes dyinglight {
  15% {
    transform: scale(1.6);
  }
  50% {
    transform: rotate(-89deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

.dl {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
}

.dl__square {
  display: block;
  width: $squareSize;
  height: $squareSize;
  background: $primary-color;
}
.dl__container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  transform-origin: 50% 50% 0;
  animation: dyinglight 1s ease infinite;
  backface-visibility: hidden;
}

.dl__corner--top:before,
.dl__corner--top:after,
.dl__corner--bottom:before,
.dl__corner--bottom:after {
  position: absolute;
  width: $cornerSize;
  height: $cornerSize;
  color: $primary-color;
  content: "";
}

.dl__corner--top::before {
  border-left: 1px solid;
  border-top: 1px solid;
  top: -$cornerPosition;
  left: -$cornerPosition;
}

.dl__corner--top::after {
  border-right: 1px solid;
  border-top: 1px solid;
  top: -$cornerPosition;
  right: -$cornerPosition;
}

.dl__corner--bottom::before {
  border-left: 1px solid;
  border-bottom: 1px solid;
  bottom: -$cornerPosition;
  left: -$cornerPosition;
}

.dl__corner--bottom::after {
  border-right: 1px solid;
  border-bottom: 1px solid;
  bottom: -$cornerPosition;
  right: -$cornerPosition;
}

.pre-container {
  background-color: #161616;
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 2000;
}
